export const LIST_BK = {
  FONBET: 'fonbet',
  MARATHON: 'marathonbet',
  BETCITY: 'betcity',
  MELBET: 'melbet'
}

export const sortBkInStatRow = {
  [LIST_BK.BETCITY]: 1,
  [LIST_BK.FONBET]: 2,
  [LIST_BK.MARATHON]: 3,
  [LIST_BK.MELBET]: 4
}

export const listForkStatuses = {
  new: 'is-new',
  favorite: 'is-favorite',
  removed: 'is-removed'
}

export const currenciesList = [{ label: 'RUB', value: 'RUB' }]

export const filterParams = [
  {
    id: 1,
    label: 'Вилок',
    name: 'forks',
    value: '...',
    additional: '...',
    sort: 1
  },
  {
    id: 2,
    label: 'Матчей',
    name: 'matches',
    value: '...',
    additional: '...',
    sort: 2
  },
  {
    id: 3,
    label: 'Событий',
    name: 'events',
    value: '...',
    additional: '...',
    sort: 3
  },
  {
    id: 4,
    label: 'Фильтры - Букмекеров',
    name: 'bookmakers',
    value: '...',
    additional: '...',
    sort: 4
  },
  {
    id: 5,
    label: 'Видов спорта',
    name: 'sports',
    value: '...',
    additional: '...',
    sort: 5
  },
  {
    id: 6,
    label: 'До начала событий',
    name: 'timeEvents',
    value: '...',
    additional: '...',
    sort: 6
  },
  {
    id: 7,
    label: 'Значения',
    name: 'typeValues',
    value: '...',
    additional: '...',
    sort: 7
  }
]

export const dataFilter = {
  sports: {
    data: [
      { label: 'Топ', type: 'top', filter: 'Топ' },
      { label: 'Футбол', type: 'football', filter: 'Футбол', active: true },
      {
        label: 'Баскетбол',
        type: 'basketball',
        filter: 'Баскетбол',
        active: true
      },
      {
        label: 'Бол. теннис',
        type: 'tennis',
        filter: 'Теннис',
        active: true
      },
      { label: 'Хоккей', type: 'hockey', filter: 'Хоккей', active: true },
      {
        label: 'Волейбол',
        type: 'volleyball',
        filter: 'Волейбол',
        active: true
      },
      {
        label: 'Киберспорт',
        type: 'esport',
        filter: 'Киберспорт',
        active: true
      }
    ],
    value: 'Топ'
  },
  profit: {
    data: [],
    value: 0
  },
  timeEvent: {
    data: [
      { label: 'Любое время', value: 'ANY_TIME' },
      { label: '1 час', value: 'ONE_HOUR' },
      { label: '2 часа', value: 'TWO_HOURS' },
      { label: '12 часов', value: 'TWELVE_HOURS' },
      { label: '1 день', value: 'ONE_DAY' },
      { label: '2 дня', value: 'TWO_DAYS' },
      { label: '7 дней', value: 'SEVEN_DAYS' }
    ],
    value: { label: 'Любое время', value: 'ANY_TIME' }
  },
  place: {
    data: [
      { label: 'Прематч', value: 'Прематч', name: 'line', disabled: false }
    ],
    value: { label: 'Прематч', name: 'line' }
  },
  bks: {
    data: [],
    value: []
  },
  isFraction: {
    value: false
  },
  showAsian: {
    value: false
  },
  showMain: {
    value: true
  },
  showHandicap: {
    value: true
  },
  showTotal: {
    value: true
  },
  showIndividualTotal: {
    value: true
  },
  deleteTimeBets: {
    value: 0
  },
  count: {
    value: 100
  },
  eventId: {
    value: null
  }
}
